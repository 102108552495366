.App {
  text-align: center;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  min-width: 1000px;
  border-top: solid 8px #1887CA;
}
.content-app {
  flex: 1;
  overflow: hidden;
}
a {
  color: #1f1f1f;
  text-decoration: none;
}
.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
* {
  padding: 0;
  margin: 0;
  line-height: 1;
}

.bottom-tip img{
    height: 30px;
    margin-right: 5px;
}

.bottom-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #333333;
  justify-content: center;
  border-top: solid 1px #cccccc;
  /* border-bottom: solid 1px #cccccc; */
  /* margin-top: 20px; */
}
ol li, ul li {
  list-style-position: inside;
}
.mt20 {
  margin-top: 20px;
}

.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30 {
  margin-bottom: 20px;
}
.mb40 {
  margin-bottom: 20px;
}
.mr10 {
  margin-right: 20px;
}
.mr20 {
  margin-right: 20px;
}
.mr30 {
  margin-right: 30px;
}

.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}

.flex {
  display: flex;
}